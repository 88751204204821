// TODO: old file. review usage and type properly

// \xa0 is a non-breaking space
export const parseSubjectKey = (key: string) => {
  if (typeof key !== 'string') {
    return ''
  }
  return (key ?? '').split('__').slice(0, 2).join('\xa0-\xa0Grade\xa0')
}

export const getGradeFromSubjectOldKey = (key: string) =>
  (key || '').split('__')[key.split('__').length - 2]

export const withoutUndefinedFields = (obj: any) =>
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key])

// must pass a list of subjects with oldKeys defined
interface WithOldKey {
  title: string
  oldKey: string
}

export const sortSubjectsList = (subjects: WithOldKey[]): WithOldKey[] => {
  if (!subjects.length) {
    return subjects
  }
  if (!subjects[0]?.oldKey) {
    console.log(
      'Unable to sort subjects list, subjects must have oldKey',
    )
    return subjects
  }
  return [...subjects].sort(function(a, b) {
    const gradeA = parseInt(getGradeFromSubjectOldKey(a?.oldKey), 10)
    const gradeB = parseInt(getGradeFromSubjectOldKey(b?.oldKey), 10)
    if (gradeA < gradeB) {
      return -1
    } else if (gradeA > gradeB) {
      return 1
    } else {
      if (a?.title < b?.title) {
        return -1
      } else if (a?.title > b?.title) {
        return 1
      }
    }
    return 0
  })
}

export const buildSafeUrl = (...parts: string[]): string => {
  return parts.join('/').replace(/([^:]\/)\/+/g, '$1')
}
