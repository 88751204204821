'use client'

// React-specific imports
import { useEffect } from 'react'

// Dependencies
import { useTranslations } from 'next-intl'

// Edwin UI-specific imports
import { Button, Spinner, Text } from '@edwin-edu/ui/server'

// Components
import { Welcome } from '@/components/Login/Welcome'

// Styles
import styles from './Login.module.scss'

interface VerifyProps {
  onSubmit: () => Promise<void>
  isError?: boolean
  isLoading?: boolean
  goBack: () => void
}

export function Verify({
  isError,
  isLoading,
  onSubmit,
  goBack
}: VerifyProps) {
  const t = useTranslations('login')

  useEffect(() => {
    onSubmit()
  }, [])

  return (
    <>
      <main className={styles.outerAuthContainer}>

        <form className={styles.innerAuthContainer} onSubmit={(e) => e.preventDefault()}>

          <div className={styles.vStack}>

            {isLoading && (
              <Spinner />
            )}

            {!isLoading && (
              <>
                <Welcome />
                <Text variant="large" mb={6} className={styles.verifyMessage}>
                  <span dangerouslySetInnerHTML={{ __html: isError ? t.raw('verifyEmailFailed') : t.raw('verifyMessage') }} />
                </Text>
                <Button type="button" onClick={onSubmit} mb={2} data-test="verify-resend-email-button">
                  {t('resendEmail')}
                </Button>
                <Button
                  variant="ghost"
                  type="button"
                  size="sm"
                  onClick={goBack}
                  data-test="verify-return-to-login-button"
                >
                  {t('returnLogin')}
                </Button>
              </>
            )}
          </div>

        </form>

      </main>
    </>
  )
}
