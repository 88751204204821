'use client'

import type Tracker from '@openreplay/tracker'

import { useEffect, useState } from 'react'

export function useOpenReplay() {
  const [tracker, setTracker] = useState<Tracker | undefined>(undefined)

  useEffect(() => {
    if (typeof window !== 'undefined' && process.env.OPEN_REPLAY_DISABLED !== 'true') {
      const tracker = window.OpenReplay as Tracker

      if (tracker != null) {
        setTracker(tracker)
        tracker.start()
      }
    }
  }, [])

  return tracker
}
