'use client'

import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect, useRef } from 'react'
import { type ApmBase, Transaction } from '@elastic/apm-rum'
import { useImmer } from 'use-immer'

export interface ElasticRumNavigationMonitorProps {
  apm: ApmBase | undefined
}

type NavData = {
  pathname: string
  searchParams: string
}

export default function ElasticNavigationMonitor(props: ElasticRumNavigationMonitorProps) {
  const { apm } = props

  const pathname = usePathname()
  const searchParams = useSearchParams()

  // use refs to ensure transaction does not cause re-renders
  const prevNavData = useRef<NavData | null>(null)
  const transaction = useRef<Transaction | null>(null)

  // use immutable state tracking for current nav data
  const [trackedNavData, setTrackedNavData] = useImmer<NavData>({
    pathname: pathname,
    searchParams: searchParams.toString(),
  })

  useEffect(() => setTrackedNavData((draft) => {
    draft.pathname = pathname
    draft.searchParams = searchParams.toString()
  }), [pathname, searchParams])

  useEffect(() => {
    if (apm != null) {
      if (transaction.current != null) {
        transaction.current.end()
        transaction.current = null
      }

      const tx = apm.startTransaction(pathname, 'route-change', {
        managed: false,
      })

      transaction.current = tx ?? null
      prevNavData.current = trackedNavData

      return () => {
        // End outstanding transaction on unmount
        if (transaction.current != null) {
          transaction.current.end()
          transaction.current = null
        }
      }
    }
  }, [apm, trackedNavData])

  return null
}