'use client'

// React-specific imports
import React, { useEffect, useState } from 'react'

// Dependencies
import { useForm } from 'react-hook-form'
import { useTranslations } from 'next-intl'
import Turnstile, { useTurnstile } from 'react-turnstile'

// Components
import ExpiredLinkSvg from '@/public/icon-expired-link.svg'
import { Button } from '@edwin-edu/ui/server'

// Styles
import styles from './Login.module.scss'

export interface ResetPasswordProps {
  goBack: () => void
  onSubmit: ({ email }: { email: string, token: string }) => void
  storedEmail?: string
  linkExpired?: boolean
  turnstilePublicKey: string
}

export const ResetPassword: React.FC<ResetPasswordProps> = ({
  goBack,
  onSubmit,
  storedEmail,
  linkExpired,
  turnstilePublicKey,
}: ResetPasswordProps) => {
  const t = useTranslations('login')
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<{ email: string }>()
  const [displaySubmit, setDisplaySubmit] = useState(false)
  const [status, setStatus] = useState<{
    loading?: null | boolean
    data?: null | boolean
    error?: null | boolean
  }>({
    loading: null,
    data: null,
    error: null,
  })
  const turnstile = useTurnstile()
  const [token, setToken] = useState<string | null>(null)

  const email = storedEmail ?? watch('email')
  storedEmail = undefined
  const invalidEmail = (email && !email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) || false

  useEffect(() => {
    if (email && email.length && !invalidEmail && token) {
      setDisplaySubmit(true)
    } else {
      setDisplaySubmit(false)
    }
  }, [email, token])

  useEffect(() => {
    if (turnstile) {
      turnstile.reset()
    }
  }, [turnstile, status?.error])

  const submitForm = (data: { email: string }): void => {
    const { email } = data
    if (email === '' || !token) return
    setStatus({ loading: true })
    setTimeout(async () => {
      try {
        await onSubmit({ email, token })
        setStatus({ data: true })
      } catch (err) {
        setStatus({ error: true })
      }
    }, 1000)
  }

  const isError = !!(errors?.email || status?.error)

  return (
    <main className={styles.outerAuthContainer}>
      <form
        className={`${styles.innerAuthContainer} ${styles.innerAuthContainerPadded}`}
        onSubmit={handleSubmit(submitForm)}
        data-show-error={isError}
      >
        {status?.data ? (
          <>
            <div className={styles.resetPasswordMessage} data-test="reset-password-link-result">
              {t('resetPasswordLinkSent')}
            </div>
            <Button
              data-test="reset-password-go-back-button"
              style={{ margin: '0 0 0 auto' }}
              onClick={goBack}
            >
              {t('gotIt')}
            </Button>
          </>
        ) : (
          <div className={styles.vStack}>
            {linkExpired ? (
              <>
                <ExpiredLinkSvg className={styles.expiredImg} data-test="reset-pswd-link-expired-img" />

                <div className={styles.expiredMessage} data-test="reset-pswd-link-expired-msg">
                  <FormattedHTMLMessage id="passwordResetLinkExpired" />
                </div>

                <p className={styles.expiredMessageP2} data-test="reset-pswd-link-expired-msg2">
                  <FormattedHTMLMessage id="passwordResetLinkExpired2" />
                </p>
              </>
            ) : (
              <div className={styles.resetMessage} data-test="reset-pswd-send-link">
                <FormattedHTMLMessage id="sendResetPasswordLink" />
              </div>
            )}

            {storedEmail ? (
              <>
                {isError && (
                  <div className={styles.inputError} data-test="reset-pswd-input-error">
                    {t(status?.error ? 'emailNotFound' : 'requiredFieldIsEmpty')}
                  </div>
                )}
                <input
                  {...register('email')}
                  type="hidden"
                  defaultValue={storedEmail}
                  data-test="reset-pswd-email-input"
                />
                <button
                  className={styles.resendButton}
                  type="submit"
                  disabled={!!errors?.email || status?.loading || invalidEmail}
                  data-test="reset-pswd-submit-button"
                >
                  {t(status?.error ? 'tryAgain' : status?.loading ? 'sending' : 'passwordResetLinkExpiredButton')}
                </button>
              </>
            ) : (
              <>
                <div className={styles.emailInputWrapper}>
                  <input
                    data-test="reset-password-email-input"
                    {...register('email', { required: true })}
                    className={`${styles.styledInput} ${isError ? styles.inputError : ''}`}
                    placeholder={t('email')}
                    aria-label="Reset password input"
                  />
                  {isError && (
                    <div className={styles.inputError} data-test="reset-pswd-input-error">
                      {t(status?.error ? 'emailNotFound' : 'requiredFieldIsEmpty')}
                    </div>
                  )}
                </div>
                <div className={styles.turnstileContainer}>
                  <Turnstile
                    sitekey={turnstilePublicKey}
                    appearance="interaction-only"
                    onVerify={(token: string) => {
                      setToken(token)
                    }}
                    theme="light"
                  />
                </div>
                <div className={styles.buttonGroup}>
                  <button
                    className={styles.backButton}
                    onClick={goBack}
                    data-test="reset-pswd-back-button"
                  >
                    {t('back')}
                  </button>
                  {displaySubmit && (
                    <button
                      className={styles.sendButton}
                      type="submit"
                      disabled={!!errors?.email || status?.loading || invalidEmail}
                      data-test="reset-pswd-submit-button"
                    >
                      {t(status?.error ? 'tryAgain' : status?.loading ? 'sending' : 'send')}
                    </button>
                  )}
                </div>
              </>
            )}

            {linkExpired && (
              <a
                className={styles.helpLink}
                href="https://help.edwin.app/s/article/Sign-in-to-Edwin"
                target="_blank"
                data-test="reset-pswd-help-link"
              >
                {t('needHelp')}
              </a>
            )}
          </div>
        )}
      </form>
    </main>
  )
}

const FormattedHTMLMessage = ({ id }: { id: string }) => {
  const t = useTranslations('login')
  return <span dangerouslySetInnerHTML={{ __html: t.raw(id) }} />
}

