import React from 'react'
import { WarningBubble } from '../SVG/WarningBubbleIcon'
import { ExternalLink } from '../SVG/ExternalLinkIcon'
import styles from './Login.module.scss'
import { useTranslations } from 'next-intl'
import { Button, Flex } from '@edwin-edu/ui/server'

interface TeacherErrorProps {
  isTitle?: boolean
  inactiveUser: boolean | null | undefined
  inactiveLicense: boolean | null | undefined
  onTryAgain?: () => void
}

export const TeacherError: React.FC<TeacherErrorProps> = ({ isTitle, inactiveUser, inactiveLicense, onTryAgain }) => {
  const t = useTranslations('login')
  return (
    <>
      {isTitle ? (
      <div className={styles.errorLicense}>
        <div className={styles.errorLicenseExpiredTitle}>
          <WarningBubble />
          {t('error.licenseExpiredTeacher')}
        </div>
        <div className={styles.userErrorNumber} data-test="error-modal-error-number">
          {'302'}
        </div>
      </div>
      ) : (
        <>
          <div className={styles.infoLicenseExpired}
          >
            {t('error.infoLicenseExpiredTeacher1')}
          </div>
          <div className={styles.infoLicenseExpired}
          >
            {t('error.infoLicenseExpiredTeacher2')}
          </div>
          <div className={styles.infoLicenseExpired}
          >
            {t('error.infoLicenseExpiredTeacher3')}
            <a
              className={styles.infoLicenseExpiredLink}
              data-test="error-modal-contact-help-link"
              href="https://help.edwin.app/s/article/Sign-in-to-Edwin"
            >
              {t('error.infoLicenseTeacherLink1')}
              {<ExternalLink />}
            </a>
          </div>
          <div className={styles.infoLicenseExpired}>
            {t('error.infoLicenseExpiredTeacher4')}
            <a
              className={styles.infoLicenseExpiredLink}
              data-test="error-modal-contact-help-link"
              href="https://help.edwin.app/s/article/Inviting-Teachers"
            >
              {t('error.infoLicenseTeacherLink2')}
              {<ExternalLink />}
            </a>
          </div>
          <Flex gap={4}>
              <Button
                className={styles.signInButton}
                data-test="error-modal-try-again-button"
                onClick={onTryAgain}
              >
                {t('error.signIn')}
              </Button>
              <Button
                className={styles.signInButton}
                data-test="error-modal-try-again-button"
                onClick={onTryAgain}
                variant="ghost"
              >
                {t('error.close')}
              </Button>
            </Flex></>
          )}
    </>
  )
}