import { El, ElProps } from '@edwin-edu/ui/server'
import c from 'classnames'
import { FC } from 'react'
import styles from './HR.module.scss'

export const HR: FC<ElProps> = ({ className, ...props }) => (
  <El className={c(styles.hr, className)} {...props} />
)

export default HR
