/* eslint-disable react/jsx-indent-props */
export const ExternalLink = () => (
  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.25781 10.9611L17.8276 2.05859" stroke="#017ACC" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.9985 6.80501V1H13.5391" stroke="#017ACC" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.63447 5.08887H3.10981C1.94615 5.08887 1 6.05823 1 7.25043V18.8382C1 20.0304 1.94615 20.9997 3.10981 20.9997H14.1591C15.3228 20.9997 16.2689 20.0304 16.2689 18.8382V10.9384" stroke="#017ACC" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

)

