'use client'

import { useEffect, useMemo } from 'react'
import { type ApmBase, init as initApm } from '@elastic/apm-rum'
import { useImmer } from 'use-immer'

import { type ApmConfig } from '@/components/Elastic/ElasticInstrumentation'

export function useElasticApm(apmConfig?: ApmConfig): ApmBase | undefined {
  // use immutable state tracking for apm config
  const [trackedApmConfig, setTrackedApmConfig] = useImmer<ApmConfig | undefined>(undefined)

  useEffect(() => {
    if (apmConfig != null) {
      setTrackedApmConfig((draft) => {
        if (draft == null) {
          // create draft state
          draft = {
            serverUrl: apmConfig.serverUrl,
            serviceName: apmConfig.serviceName,
            serviceVersion: apmConfig.serviceVersion,
            environment: apmConfig.environment,
          }
          return draft
        }

        // update draft state
        draft.serverUrl = apmConfig.serverUrl
        draft.serviceName = apmConfig.serviceName
        draft.serviceVersion = apmConfig.serviceVersion
        draft.environment = apmConfig.environment
      })
    }
  }, [apmConfig])

  // used tracked apm config to ensure apm is only initialized once
  const apm = useMemo(() => {
    if (trackedApmConfig != null) {
      return initApm({
        serverUrl: trackedApmConfig.serverUrl,
        serviceName: trackedApmConfig.serviceName,
        serviceVersion: trackedApmConfig.serviceVersion,
        environment: trackedApmConfig.environment,
      })
    }
  }, [trackedApmConfig])

  if (apm == null) {
    return undefined
  }

  return apm
}
