import React, { FC, PropsWithChildren } from 'react'
import styles from './Login.module.scss'
import classNames from 'classnames'

export interface AuthContainerProps {
  showError?: boolean | undefined;
  className?: string;
}

export const AuthContainer: FC<PropsWithChildren<AuthContainerProps>> = (props) => (
  <main className={styles.outerAuthContainer}>
    <div className={props.showError ? classNames(styles.innerAuthContainer, styles.showError, props.className) : `${styles.innerAuthContainer}`}>
      {props.children}
    </div>
  </main>
)