'use client'

// React-specific imports
import React, { useEffect, useState } from 'react'

// Next.js-specific imports
import Image from 'next/image'

// Dependencies
import { useTranslations } from 'next-intl'
import { useForm } from 'react-hook-form'

// Edwin UI-specific imports
import { Button, Spinner } from '@edwin-edu/ui/server'

// Components
import { AuthContainer } from '@/components/Login/AuthContainer'
import AlertSVG from '@/public/icon-alert-bubble.svg'

// Styles
import styles from './Login.module.scss'

export interface ExistingUserModalProps {
  userEmail: string
  errorMessage?: string
  authError?: string
  showSpinner?: boolean
  onSubmit: (data: { password: string }) => void
  handleForgotPassword: () => void
  goBack: () => void
}

export const EdwinAuth: React.FC<ExistingUserModalProps> = ({
  userEmail,
  errorMessage,
  authError,
  showSpinner,
  onSubmit,
  handleForgotPassword,
  goBack,
}: ExistingUserModalProps) => {
  const t = useTranslations('login')

  const [didSubmitPassword, setDidSubmitPassword] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ password: string }>()

  useEffect(() => {
    setDidSubmitPassword(false)
  }, [errorMessage, errors.password])

  const submitForm = async (data: { password: string }): Promise<void> => {
    setDidSubmitPassword(true)
    onSubmit(data)
  }

  const required = t('enterPassword')

  const showError = !!(errors.password || errorMessage)
  const errorInfo = errors.password ? required : errorMessage || null

  return (
    <AuthContainer showError={showError}>

      <div className={styles.welcomeContainer}>
        <div className={styles.passwordHeader} data-test="login-existing-user-modal-header">
          {t('hello')}
        </div>

        <div className={styles.userEmail} data-test="login-existing-user-modal-email">
          {userEmail}
        </div>
      </div>

      <form className={styles.bottomRow} onSubmit={handleSubmit(submitForm)}>
        <input
          type="password"
          {...register('password', { required })}
          className={styles.styledInput}
          placeholder={required}
          autoFocus
          data-test="login-existing-user-edwin-auth-password-input"
          aria-label="Login existing user edwin auth password input"
        />

        {errorMessage === 'auth/wrong-password' && (
          <div className={styles.styledFieldError} data-test="login-existing-user-modal-error">
            <Image src={AlertSVG} alt="alert icon" className={styles.alertIcon} data-test="login-existing-user-modal-error-icon" />
            {t('error.info')}
          </div>
        )}

        <div className={styles.leftSide}>
          {errorMessage && !errors.password && (
            <a
              className={styles.helpLink}
              href="https://help.edwin.app/s/article/Sign-in-to-Edwin"
              target="_blank"
              data-test="login-existing-user-modal-help-link"
            >
              {t('needHelp')}
            </a>
          )}
        </div>

        <div className={styles.navButtons}>

          <Button
            variant="unstyled"
            className={styles.forgotPassword}
            type="button"
            onClick={() => {
              handleForgotPassword()
            }}
            data-test="login-existing-user-modal-forgot-password-button"
          >
            {t('forgotPassword')}
          </Button>

          <div className={styles.buttonsContainer}>
            <Button
              variant="ghost"
              size={'sm'}
              className={styles.backButton}
              type="button"
              onClick={goBack}
              data-test="login-existing-user-modal-back-button"
            >
              {t('back')}
            </Button>

            {didSubmitPassword && showSpinner ? (
              <Spinner
                size={30}
                thickness={5}
                className={styles.spinner}
                data-test="login-existing-user-modal-spinner"
              />
            ) : (
              <Button
                size={'md'}
                className={styles.continueButton}
                type="submit"
                data-test="login-existing-user-modal-continue-button"
                name="email-login-button"
              >
                {t('continue')}
              </Button>
            )}
          </div>

        </div>

      </form>
    </AuthContainer>
  )
}