'use client'

// Next.js-specific imports
import Image from 'next/image'

// Dependencies
import { useTranslations } from 'next-intl'

// Edwin UI-specific imports
import { H1 } from '@edwin-edu/ui/server'

// Components
import LogoSvg from '@/public/logo.svg'

// Styles
import styles from './Welcome.module.scss'

export function Welcome() {
  const t = useTranslations('login')

  return (
    <H1 className={styles.welcome} mb={8}>
      {t('welcomeToEdwin')}{' '}
      <Image
        data-test="logo-image"
        src={LogoSvg}
        width="110"
        height="34"
        alt="edwin"
        className={styles.logo}
      />
      <span className={styles.blue}>!</span>
    </H1>
  )
}
