'use client'

// React-specific imports
import React from 'react'

// Dependencies
import { useTranslations } from 'next-intl'

// Edwin UI-specific imports
import { Button } from '@edwin-edu/ui/server'

// Components
import { AuthContainer } from '@/components/Login/AuthContainer'
import { StudentError } from './StudentError'
import { TeacherError } from './TeacherError'

//SVG

// Styles
import styles from './Login.module.scss'

export interface ErrorModalProps {
  onTryAgain: () => void
  inactiveUser: boolean | null | undefined
  inactiveLicense: boolean | null | undefined
  isStudent: boolean | null | undefined
  isTeacher: boolean | null | undefined
  unknownError: boolean | null | undefined
  joinUrl: string
}

export const LoginError: React.FC<ErrorModalProps> = ({
  onTryAgain,
  inactiveUser,
  inactiveLicense,
  unknownError,
  isStudent,
  isTeacher,
  joinUrl,

}) => {
  const t = useTranslations('login')

  return (
    <AuthContainer showError className={isStudent || isTeacher ? styles.userError : ''}>
      <div className={isStudent || isTeacher ? styles.userErrorContainer : styles.errorContainer}>

        {!unknownError && (<>
            <div className={styles.errorNumber} data-test="error-modal-error-number">
          {!isTeacher && !isStudent && (inactiveUser ? '302' : inactiveLicense ? '381' : '300')}
            </div>

            <h1 className={isStudent || isTeacher ? styles.userErrorTitle : styles.errorTitle} data-test="error-modal-title">
            {inactiveUser ? (
          isTeacher ? (
            <TeacherError isTitle={true} inactiveUser={inactiveUser} inactiveLicense={inactiveLicense} />
          ) : isStudent ? (
            <StudentError isTitle={true} inactiveUser={inactiveUser} inactiveLicense={inactiveLicense} />
          ) : (
            t('error.licenseExpired')
          )
            ) : !inactiveLicense ? t('error.inactiveLicense') : t('error.accountNotRecognized')}
          </h1>

            <div className={isStudent || isTeacher ? styles.userErrorDesc : styles.errorDesc} data-test="error-modal-description">
            {inactiveUser ? (
          isTeacher ? (
            <TeacherError inactiveUser={inactiveUser} inactiveLicense={inactiveLicense} onTryAgain={onTryAgain}/>
          ) : isStudent ? (
                  <StudentError inactiveUser={inactiveUser} inactiveLicense={inactiveLicense} onTryAgain={onTryAgain} joinUrl={joinUrl} />
          ) : (
            t('error.infoLicenseExpired')
          )
            ) : inactiveLicense ? t('error.infoLicenseInactive') : t('error.infoNotRecognized')}
          </div>
        </>)}

        {unknownError && (<>
          <div className={styles.errorNumber} data-test="error-modal-error-number">
        382
          </div>

          <h1 className={styles.errorTitle} data-test="error-modal-title">
        {t('error.unknown')}
          </h1>

          <p className={styles.errorDesc} data-test="error-modal-description">
        {t('error.infoUnknown')}
          </p>
        </>)}

        {!inactiveUser && !inactiveLicense && (
          <p className={styles.errorSubDesc} data-test="error-modal-contact-info">
        {t('error.contactInfo')}
        <a
          className={styles.contact}
          data-test="error-modal-contact-help-link"
          href="https://help.edwin.app/s/article/Solution-for-Error-300"
        >
          {t('error.contactUs')}
        </a>
          </p>
        )}

        {!isStudent && !isTeacher && (
          <Button
          className={styles.tryAgainButton}
          data-test="error-modal-try-again-button"
          onClick={onTryAgain}
        >
          {t('tryAgain')}
        </Button>
        )}

      </div>
    </AuthContainer>
  )
}