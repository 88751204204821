'use client'

import { Suspense } from 'react'

import { type ApmConfig, type UserData } from './ElasticInstrumentation'
import ElasticNavigationMonitor from './ElasticNavigationMonitor'
import ElasticUserSessionMonitor from './ElasticUserSessionMonitor'
import { useElasticApm } from '@/hooks/useElasticApm'

export interface ElasticClientInstrumentationProps {
  apmConfig: ApmConfig
  userData?: UserData
}

export default function ElasticClientInstrumentation(props: ElasticClientInstrumentationProps) {
  const { apmConfig, userData } = props

  const apm = useElasticApm(apmConfig)

  return (
    <Suspense fallback={null}>
      <ElasticUserSessionMonitor apm={apm} userData={userData} />
      <ElasticNavigationMonitor apm={apm} />
    </Suspense>
  )
}
