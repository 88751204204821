'use client'

// React-specific imports
import { useCallback, useEffect, useState } from 'react'

// Next.js-specific imports
import { useRouter, useSearchParams } from 'next/navigation'

// Firebase-specific imports
import {
  AuthProvider,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  linkWithCredential,
  OAuthProvider,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth'

import {
  getFunctions,
  HttpsCallable,
  httpsCallable
} from 'firebase/functions'

import { getApp, getApps, initializeApp } from 'firebase/app'

// Components
import { Footer } from '@/components/Footer'
import { Header } from '@/components/Header'
import { Login } from '@/components/Login/Login'
import { EdwinAuth } from '@/components/Login/EdwinAuth'
import { LoginError } from '@/components/Login/LoginError'
import { ResetPassword } from '@/components/Login/ResetPassword'
import { Verify } from '@/components/Login/Verify'

// Actions
import { updateUser } from '@/actions/user/updateUser'

// Utilities, Globals, Constants
import { buildSafeUrl } from '@/utils/misc'
import { JOIN_PATH } from '@/constants'
import { ApmConfig } from '../Elastic/ElasticInstrumentation'
import { useElasticApm } from '@/hooks/useElasticApm'
import { getUser } from '@/actions/user/getUser'
import { EdwinTypes } from '@/services'

interface LoginWrapperProps {
  url: string

  apmConfig?: ApmConfig

  firebaseAPIKey?: string
  firebaseAuthDomain?: string
  firebaseProjectId?: string
  firebaseAppId?: string

  turnstilePublicKey?: string
}

interface ResultData {
  success: boolean
}

interface ResetPasswordRequest {
  email: string
  token: string
}

interface EmailSubmitRequest {
  email: string
}

interface SSOSubmitRequest {
  email: string
}

type SendPasswordResetEmailResponse = HttpsCallable<unknown, ResultData>
type VerifyEdwinUserResponse = HttpsCallable<unknown, ResultData>
type SendVerificationEmailResponse = HttpsCallable<unknown, ResultData>
type ExchangeProviderTokenResponse = HttpsCallable<unknown, ResultData>

export default function LoginWrapper({
  url,

  apmConfig,

  firebaseAPIKey,
  firebaseAuthDomain,
  firebaseProjectId,
  firebaseAppId,

  turnstilePublicKey = '',
}: LoginWrapperProps) {
  // Router
  const router = useRouter()
  const searchParams = useSearchParams()
  const redirectUrl = searchParams.get('redirect') || '/'
  let cluster = url.includes('stage') ? 'stage' : 'prod'

  if (url.includes('ga1')) {
    cluster = 'ga1'
  }
  if (url.includes('ga2')) {
    cluster = 'ga2'
  }

  const app = getApps().length > 0 ? getApp() : initializeApp({
    apiKey: firebaseAPIKey,
    authDomain: firebaseAuthDomain,
    projectId: firebaseProjectId,
    appId: firebaseAppId,
  })

  const firebaseAuth = getAuth(app)
  const firebaseApp = firebaseAuth.app

  const googleProvider = new GoogleAuthProvider()
  const microsoftProvider = new OAuthProvider('microsoft.com')

  const firebaseFunctions = getFunctions(firebaseApp, 'northamerica-northeast1')
  const verifyEdwinUser: VerifyEdwinUserResponse = httpsCallable(firebaseFunctions, 'verifyEdwinUser')
  const sendVerificationEmail: SendVerificationEmailResponse = httpsCallable(firebaseFunctions, 'sendVerificationEmail')
  const sendPasswordResetEmail: SendPasswordResetEmailResponse = httpsCallable(firebaseFunctions, 'sendPasswordResetEmail')
  const exchangeProviderToken: ExchangeProviderTokenResponse = httpsCallable(firebaseFunctions, 'exchangeProviderToken')

  // State
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [signInWithEmail, setSignInWithEmail] = useState(false)
  const [allAuthentication, setAllAuthentication] = useState(true)
  const [edwinAuthentication, setEdwinAuthentication] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [verifyEmail, setVerifyEmail] = useState(false)
  const [isVerifyLoading, setIsVerifyLoading] = useState(false)
  const [isVerifyError, setIsVerifyError] = useState(false)
  const [inactiveUser, setInactiveUser] = useState(false)
  const [isStudent, setIsStudent] = useState(false)
  const [isTeacher, setIsTeacher] = useState(false)
  const [unknownError, setUnknownError] = useState(false)
  const [inactiveLicense, setInactiveLicense] = useState(false)
  const [error, setError] = useState(false)
  const [authError, setAuthError] = useState('')

  const apm = useElasticApm(apmConfig)

  useEffect(() => {
    getRedirectResult(firebaseAuth).then(async (userCred) => {
      if (!userCred) {
        return
      }

      fetch(window.location.origin + '/api/login', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${await userCred.user.getIdToken()}`,
        },
      }).then(async (response) => {
        if (response.status === 200) {
          await updateUser({
            lastSeen: new Date().toISOString(),
          })
          router.push(redirectUrl)
        }
      }).catch((error) => {
        console.error('Error verifying user:', error)
        setUnknownError(true)
        setError(true)
      })
    })
  }, [])

  async function handleSubmit(password: string) {
    let userCredential
    try {
      setShowSpinner(true)
      userCredential = await signInWithEmailAndPassword(getAuth(), email, password)
    } catch (error: any) {
      if (error.code === 'auth/wrong-password') {
        setAuthError('auth/wrong-password')
        setShowSpinner(false)
      } else if (error.code === 'auth/account-exists-with-different-credential') {
        // NOTE: This should never happen as an account cannot make it to this point unless it exists in some form already
        console.warn(`Invalid scenario: ${error.code}`)
      } else {
        setShowSpinner(false)
        setAuthError(error.code)
        console.error('Error logging in:', error)
      }
      return
    }

    const idToken = await userCredential.user.getIdToken()

    try {
      const response = await fetch(window.location.origin + '/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ idToken }),
      })

      if (response.status !== 200) {
        throw new Error('Failed to set session cookie')
      }
      await updateUser({
        lastSeen: new Date().toISOString(),
      })
    } catch (error) {
      console.error('Error verifying user:', error)
      setUnknownError(true)
      setError(true)
    }

    await userCredential.user.reload()

    setTimeout(() => {
      router.push(redirectUrl)
    }, 300)
  }

  async function handleSsoSubmit(data: SSOSubmitRequest) {
    apm?.getCurrentTransaction()

    const apmSpan = apm?.startSpan('edwin.login.sso_submit', 'component')

    try {
      setError(false)
      setShowSpinner(true)

      const result = await verifyEdwinUser({
        email: data.email.trim(),
        version: 'sso',
        cluster
      })

      if (result)

        if (result.data.success === true) {
          setEmail(data.email)
          setAllAuthentication(false)
          setShowSpinner(false)

          return true
        } else {
          setError(true)
          setShowSpinner(false)
        }
    } catch (error: any) {
      apm?.captureError(error)

      setEdwinAuthentication(false)
      setAllAuthentication(false)

      if (!error?.details) {
        setUnknownError(true)
        apmSpan?.addLabels({ 'edwin.login.error_code': '382', })
      }

      if (error?.details?.code === 'auth/invalid-edwin-user') {
        if (error?.details?.role === 'Student') {
          setIsStudent(true)
        } else if (error?.details?.role === 'Teacher' || error?.details?.role === 'Admin') {
          setIsTeacher(true)
        }

        setInactiveUser(true)
        apmSpan?.addLabels({ 'edwin.login.error_code': '302', })
      }

      if (error?.details?.code === 'auth/invalid-edwin-license') {
        setInactiveLicense(true)
        apmSpan?.addLabels({ 'edwin.login.error_code': '381', })
      }

      if (error?.details?.code === 'auth/no-password') {
        setEmail(data.email)
      } else {
        setError(true)
      }

      setShowSpinner(false)
    } finally {
      apmSpan?.end()
    }

    return false
  }

  async function signInWithProvider(provider: AuthProvider) {
    if (provider instanceof GoogleAuthProvider) {
      provider.setCustomParameters({ prompt: 'select_account' })
    } else if (provider instanceof OAuthProvider) {
      provider.setCustomParameters({ prompt: 'select_account' })
    }

    let result: UserCredential | null = null
    let validEdwinUser = false

    try {
      result = await signInWithPopup(firebaseAuth, provider)
    } catch (exception: any) {
      const { code } = exception

      try {
        if (code === 'auth/account-exists-with-different-credential') {
          const credential = OAuthProvider.credentialFromError(exception)

          if (!credential) {
            console.error('Credential is undefined or invalid')
            return
          }

          const { accessToken, idToken, providerId } = credential as any

          const exchangeResult = await exchangeProviderToken({
            providerId: providerId,
            idToken: idToken,
            accessToken: accessToken,
            source: 'next-core',
          })

          if (exchangeResult && credential) {
            const { token } = exchangeResult.data

            const firebaseUser = await signInWithCustomToken(firebaseAuth, token)

            if (firebaseUser) {
              try {
                await linkWithCredential(firebaseUser.user, credential)
              } catch (linkError) {
                console.error('Error linking credential:', linkError)
              }
            }
          }
        }
      } catch (error) {
        console.error('Error during credential exchange or linking:', error)
      }
    }

    if (result?.user?.email) {
      validEdwinUser = await handleSsoSubmit({ email: result.user.email })
    }

    if (validEdwinUser) {
      // set cookie from firebase
      const idToken = await result?.user?.getIdToken()

      await fetch(window.location.origin + '/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ idToken }),
      }).then(async (response) => {
        if (response.status === 200) {
          const updateUserRequest: EdwinTypes.user.v1.IUser = {
            lastSeen: new Date().toISOString(),
          }

          const user = await getUser()

          if (!user) {
            setTimeout(() => {
              // NOOP
            }, 1000)

            return
          }

          if (!user?.name || !user?.name.displayName || !user?.name?.familyName || !user?.name?.givenName) {
            const splitEmail = result?.user?.email?.split('@') ?? []
            const tokenName = result?.user.displayName ?? ''
            const splitName = tokenName.split(' ')
            const givenName = splitName.length === 2 ? splitName[0] : splitEmail[0]
            const familyName = splitName.length === 2 ? splitName[1] : splitEmail[1]
            updateUserRequest.name = {
              givenName,
              familyName,
              displayName: tokenName,
            }
          }

          await updateUser(updateUserRequest)
          router.push(redirectUrl)
        } else {
          console.error('Error verifying user:', response)
          setUnknownError(true)
          setError(true)
        }
      }).catch((error) => {
        console.error('Error verifying user:', error)
        setUnknownError(true)
        setError(true)
      })
    }
  }

  const handleVerify = useCallback(async () => {
    if (isVerifyLoading) {
      return
    }

    setIsVerifyError(false)
    setShowSpinner(true)
    setIsVerifyLoading(true)

    try {
      const result = await sendVerificationEmail({
        email: email,
        url: url,
        cluster: cluster,
      })

      if (result.data.success === true) {
        setAuthError('')
      } else {
        setIsVerifyError(true)
        setAuthError('Error sending verification email')
      }

      console.log('result', result)
    } catch (error) {
      setIsVerifyError(true)
      setAuthError('Error sending verification email')
    } finally {
      // A small delay to prevent rapid re-tries
      setTimeout(() => {
        setIsVerifyLoading(false)
        setShowSpinner(false)
      }, 1000)
    }
  }, [email, url, cluster, isVerifyLoading, sendVerificationEmail])

  async function handleResetPassword(data: ResetPasswordRequest) {
    setShowSpinner(true)

    const result = await sendPasswordResetEmail({
      email: data.email,
      token: data.token
    })

    if (result.data.success === true) {
      setShowSpinner(false)
      setAuthError('')
    }

    console.log('result', result)
  }

  async function handleEmailSubmit(data: EmailSubmitRequest) {
    apm?.getCurrentTransaction()
    const apmSpan = apm?.startSpan('edwin.login.email_submit', 'component')

    try {
      setError(false)
      setShowSpinner(true)

      const result = await verifyEdwinUser({ email: data.email.trim(), version: 'next-core', cluster })

      if (result.data.success === true) {
        setEmail(data.email)
        setEdwinAuthentication(true)
        setAllAuthentication(false)
        setShowSpinner(false)
      } else {
        setError(true)
        setShowSpinner(false)
      }
    } catch (error: any) {
      apm?.captureError(error)

      setEdwinAuthentication(false)
      setAllAuthentication(false)

      if (!error?.details) {
        setUnknownError(true)
        apmSpan?.addLabels({ 'edwin.login.error_code': '382', })
      }

      if (error?.details?.code === 'auth/invalid-edwin-user') {
        if (error?.details?.role === 'Student') {
          setIsStudent(true)
        } else if (error?.details?.role === 'Teacher' || error?.details?.role === 'Admin') {
          setIsTeacher(true)
        }

        setInactiveUser(true)
        apmSpan?.addLabels({ 'edwin.login.error_code': '302', })
      }

      if (error?.details?.code === 'auth/invalid-edwin-license') {
        setInactiveLicense(true)
        apmSpan?.addLabels({ 'edwin.login.error_code': '381', })
      }

      if (error?.details?.code === 'auth/no-password') {
        setEmail(data.email)
        setVerifyEmail(true)
      } else {
        setError(true)
      }

      setShowSpinner(false)
    } finally {
      apmSpan?.end()
    }
  }

  function tryAgain() {
    setError(false)
    setEdwinAuthentication(false)
    setAllAuthentication(true)
    setInactiveUser(false)
    setUnknownError(false)
    setInactiveLicense(false)
    setEmail('')
    setShowSpinner(false)
  }

  return (
    <>
      <Header />

      {verifyEmail && (
        <Verify
          onSubmit={handleVerify}
          isError={isVerifyError}
          isLoading={isVerifyLoading}
          goBack={() => {
            setVerifyEmail(false)
            setAllAuthentication(true)
            setInactiveUser(false)
            setInactiveLicense(false)
            setShowSpinner(false)
          }}
        />
      )}

      {edwinAuthentication && (
        <EdwinAuth
          onSubmit={(data) => {
            handleSubmit(data.password)
          }}
          handleForgotPassword={() => {
            setEdwinAuthentication(false)
            setAllAuthentication(false)
            setInactiveUser(false)
            setInactiveLicense(false)
            setUnknownError(false)
            setShowSpinner(false)
            setForgotPassword(true)
          }}
          goBack={() => {
            setEdwinAuthentication(false)
            setAllAuthentication(true)
            setInactiveUser(false)
            setInactiveLicense(false)
            setUnknownError(false)
            setShowSpinner(false)
          }}
          userEmail={email}
          authError={authError}
          errorMessage={authError}
          data-test="auth-modal"
          showSpinner={showSpinner}
        />
      )}

      {allAuthentication && (
        <Login
          onProviderLogin={(provider) => {
            if (provider === 'google') {
              signInWithProvider(googleProvider)
            }

            if (provider === 'microsoft') {
              signInWithProvider(microsoftProvider)
            }
          }}
          onEmailLogin={(email) => {
            setSignInWithEmail(true)
          }}
          showEmailField={signInWithEmail}
          onSubmit={handleEmailSubmit}
          showSpinner={showSpinner}
          joinUrl={buildSafeUrl(url + JOIN_PATH)}
          data-test="login-modal"
        />
      )}

      {forgotPassword && (
        <ResetPassword
          storedEmail={email ?? undefined}
          goBack={() => {
            setForgotPassword(false)
            setEdwinAuthentication(true)
            setAllAuthentication(false)
            setInactiveUser(false)
            setInactiveLicense(false)
            setShowSpinner(false)
          }}
          onSubmit={handleResetPassword}
          linkExpired={false}
          turnstilePublicKey={turnstilePublicKey}
        />
      )}

      {error && (
        <LoginError
          inactiveUser={inactiveUser}
          inactiveLicense={inactiveLicense}
          isStudent={isStudent}
          isTeacher={isTeacher}
          unknownError={unknownError}
          onTryAgain={tryAgain}
          joinUrl={buildSafeUrl(url + JOIN_PATH)}
        />
      )}

      <Footer />
    </>
  )
}
