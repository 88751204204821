import React from 'react'
import { WarningBubble } from '../SVG/WarningBubbleIcon'
import { ExternalLink } from '../SVG/ExternalLinkIcon'
import HR from '../HR'
import styles from './Login.module.scss'
import { useTranslations } from 'next-intl'
import { Button, Flex } from '@edwin-edu/ui/server'
// Next.js-specific imports
import { useRouter } from 'next/navigation'

interface StudentErrorProps {
  isTitle?: boolean
  inactiveUser: boolean | null | undefined
  inactiveLicense: boolean | null | undefined
  onTryAgain?: () => void
  joinUrl?: string
}

export const StudentError: React.FC<StudentErrorProps> = ({ isTitle, inactiveUser, inactiveLicense, onTryAgain, joinUrl }) => {
  const t = useTranslations('login')
  const router = useRouter()

  return (
    <>
      {isTitle ? (
      <div className={styles.errorLicense}>
        <div className={styles.errorLicenseExpiredTitle}>
          <WarningBubble />
          {t('error.licenseExpiredStudent')}
        </div>
        <div className={styles.userErrorNumber} data-test="error-modal-error-number">
          {'302'}
        </div>
      </div>
      ) : (
          <>
            <div>
              <div className={styles.infoLicenseExpired}
              >
                {t('error.infoLicenseExpiredStudent1')}
              </div>
              <div className={styles.infoLicenseExpired}
              >
                {t('error.infoLicenseExpiredStudent2')}
              </div>
              <div className={styles.infoLicenseExpired}
              >
                {t('error.infoLicenseExpiredStudent3')}
                  <a
                    className={styles.infoLicenseExpiredLink}
                    data-test="error-modal-contact-help-link"
                    href="https://help.edwin.app/s/article/Inviting-Students"
                  >
                  {t('error.infoLicenseStudentLink1')}{<ExternalLink />}
                </a>
              </div>
            </div>
            <HR className={styles.hr}/>
            <div>
              <div className={styles.infoLicenseExpired}>
                {t('error.infoLicenseExpiredStudent4')}
              </div>
              <div className={styles.infoLicenseExpired}>
                {t('error.infoLicenseExpiredStudent5')}
              </div>
              <ol className={styles.infoLicenseExpired}>
                <li>
                  <div className={styles.infoLicenseExpired}>
                    {t('error.infoLicenseExpiredStudent6')}
                  </div>
                </li>
                <li>
                  <div className={styles.infoLicenseExpired}>
                    {t('error.infoLicenseExpiredStudent7')}
                    <strong>{t('error.infoLicenseExpiredStudent71')}</strong>
                    {t('error.infoLicenseExpiredStudent72')}
                  </div>
                </li>
              </ol>
              <div className={styles.infoLicenseExpired}>
                {t('error.infoLicenseExpiredStudent8')}
                <a
                  className={styles.infoLicenseExpiredLink}
                  data-test="error-modal-contact-help-link"
                  href="https://help.edwin.app/s/article/Sign-in-to-Edwin-Students"
                >
                  {t('error.infoLicenseStudentLink2')}{<ExternalLink />}
                </a>
              </div>
            </div>
            <Flex gap={4}>
              <Button
                className={styles.signInButton}
                data-test="error-modal-try-again-button"
                onClick={(e) => {
                  e.preventDefault()
                  console.log('Join Url: ', joinUrl)
                if (joinUrl) {
                  router.push(joinUrl)
                }
              }}
              >
                {t('error.classCode')}
              </Button>
              <Button
                className={styles.signInButton}
                data-test="error-modal-try-again-button"
                onClick={onTryAgain}
                variant="ghost"
              >
                {t('error.close')}
              </Button>
            </Flex>
          </>
          )}
    </>
  )
}