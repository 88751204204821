import { Flex } from '@edwin-edu/ui/src/lib/Flex'

import styles from './Footer.module.scss'

export function Footer() {
  return (
    <Flex direction="column" as="footer" className={styles.container}>
      <div>
        <a className={styles.link} href="https://www.nelson.com/privacy.html">
          Privacy Policy
        </a>
        <span className={styles.separator}>·</span>
        <a className={styles.link} href="https://edwin.app/terms-of-use">
          Terms and Conditions
        </a>
      </div>
      <p className={styles.copyright}>© {
        new Date().getFullYear()
      } Nelson</p>
    </Flex>
  )
}
